import truckApi from '@/api/truck.api'
import { ReportConversionUtil } from '@colven/common-domain-lib/lib'
import { getTimeOffset } from '@/tools/functions'
import TruckHistoricalReportConstants from '../constants/truckHistoricalReport.constants'

async function getHistoricalReportDeferred (thingsId, from, to, things, timeFormat, route) {
  const filters = {
    thingsId,
    thingsData: things,
    from,
    to,
    route,
    timeFormat
  }
  try {
    await truckApi.getHistoricalReportDeferred(thingsId, from, to, things, route, filters)
  } catch (error) {
    console.error(error)
  }
  return null
}

const processData = (data) => {
  const timeOffset = getTimeOffset()
  const lang = localStorage.getItem('locale')
  const report = {}
  report.headersSummary = TruckHistoricalReportConstants.summaryHeaders(lang)
  report.dataSummary = processSummaryData(data.summary)
  report.headersDetail = TruckHistoricalReportConstants.detailHeaders(lang)
  report.dataDetail = processDetailData(data.history, timeOffset)
  report.chart = TruckHistoricalReportConstants.chart(lang, report.dataSummary)
  return report
}

const processSummaryData = (data) => {
  const result = []
  if (data) {
    Object.keys(data).forEach(key => {
      result.push({
        harvestFrontName: key,
        times: data[key]
      })
    })
  }
  return result
}

const processDetailData = (data, offset) => {
  if (data) {
    data.forEach(d => {
      const vinculationDate = d.vinculation ? ReportConversionUtil.applyTimezone(d.vinculation, offset) : undefined
      const disengagementDate = d.disengagement ? ReportConversionUtil.applyTimezone(d.disengagement, offset) : undefined
      d.vinculation = vinculationDate ? vinculationDate.dateString + ' ' + vinculationDate.timeString : undefined
      d.disengagement = disengagementDate ? disengagementDate.dateString + ' ' + disengagementDate.timeString : undefined
    })
    return data
  }
  return []
}

export default {
  getHistoricalReportDeferred,
  processData
}
