import Vue from 'vue'
import i18n from '@/i18n'
import { mapActions, mapGetters } from 'vuex'
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue'
import BreadcrumbComponent from '@/components/commons/breadcrumb/BreadcrumbComponent.vue'
import truckApi from '@/api/truck.api'
import reportStorageApi from '@/api/report-storage.api'
import { filterRulesMapped } from '@/tools/filterRules'
import thingApi from '../../../../api/thing.api'
import { getDateTimeRange, statisticFunction, selectorDateTimeValidation, ISODateFromUnixTimestamp } from '@/tools/functions'
import moment from 'moment'
import { ReportConversionUtil, TimeRanges, CustomTimeRangeTypes, TimeFormat } from '@colven/common-domain-lib/lib'
import truckHistoricalReportService from '../../../../business/truckHistoricalReportService'

export default {
  name: 'TruckHistoricalReportComponent',
  components: {
    SelectorComponent,
    BreadcrumbComponent
  },
  props: {
  },
  data: () => ({
    summaryTableHeaders: [],
    summaryTableFilterRules: {},
    summaryTableData: [],
    detailTableHeaders: [],
    detailTableFilterRules: {},
    detailTableData: [],
    tableDataKey: '',
    chartConfig: [],
    showSelector: false,
    selectorModel: {
      showClose: true,
      filters: [
        {
          id: 'trucks',
          name: i18n.t('trucks.title'),
          show: false,
          disabled: false,
          showDialog: false,
          data: [],
          selectedData: [],
          selectAction: undefined
        }
      ],
      selects: [],
      disableGetReportButton: true,
      showDateAndTimeSelector: true,
      showTimeFormatSelector: true,
      selectedTimeFormat: {
        value: TimeFormat.HH_MM_SS.id,
        text: TimeFormat.HH_MM_SS.name
      },
      selectedDateAndTimeRange: TimeRanges.LAST_HOUR,
      selectedDateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
      sinceDate: null,
      sinceTime: null,
      toDate: null,
      toTime: null,
      customDateTimeValidForm: false,
      autoReport: false,
      autoReportMinutes: 10
    },
    selectorDialogNormalizer (node) {
      return {
        id: node._id,
        label: node.name,
        children: undefined
      }
    },
    timeFormat: null,
    intervalId: null,
    thingsId: [],
    things: [],
    timeStampFrom: moment().unix(),
    timeStampTo: moment().subtract(1, 'hours').unix(),
    loadingSpinner: true,
    settingDisabled: false,
    loadingTable: false,
    disableRefreshTable: true,
    tabs: [
      {
        id: 'tab-historical',
        name: i18n.t('trucks.reports.historical.title')
      }
    ],
    selectedTab: 'tab-historical',
    breadcrumbButtons: [],
    calcs: [
      {
        id: 0,
        name: i18n.t('table.total')
      }
    ],
    chartComponentModel: {
      showRangeSelector: false,
      rangeSelectorData: []
    },
    reportNameForChart: [{ id: 'reportName', name: `<b>${i18n.t('navbar.categories.transporte.submenu.historicocamiones.title')}</b>` }]
  }),
  created () {
    this.breadcrumbButtons.push(
      {
        id: 'setting-btn',
        icon: 'settings',
        show: this.showSelectorButton.bind(this),
        disable: this.disableSelectorButton.bind(this),
        function: this.selector.bind(this)
      })
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    // Inicializo los datos del selector
    await this.initializeSelector()
    const reportKey = this.$route.query.key
    this.getData(reportKey)
  },
  beforeDestroy () {
    this.closeReportDefer()
    clearInterval(this.intervalId)
    this.intervalId = null
    this.setDetails({ detailData: null, dataStructure: null })
  },
  computed: {
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    })
  },
  methods: {
    ...mapActions('breadcrumb', {
      setDetails: 'setDetails'
    }),
    ...mapActions('reportDefer', {
      showReportDefer: 'showReportDefer',
      closeReportDefer: 'closeReportDefer',
      setVisible: 'setVisible'
    }),
    updateFilterModelDate (filterData) {
      const filterDataTimeRange = filterData.dateTimeRange || null
      const filterDataTimeType = filterData.customDateTimeRangeType || null

      if (filterDataTimeRange == null && filterDataTimeType == null) {
        if (filterData.from || filterData.to) {
          this.selectorModel.selectedDateAndTimeRange = TimeRanges.CUSTOM
          this.selectorModel.selectedDateAndTimeRangeCustomType = CustomTimeRangeTypes.DATE_AND_TIME
        }
      } else {
        this.selectorModel.selectedDateAndTimeRange = filterDataTimeRange
        this.selectorModel.selectedDateAndTimeRangeCustomType = filterDataTimeType
      }

      if (this.selectorModel.selectedDateAndTimeRange === TimeRanges.CUSTOM) {
        const sinceDateData = ISODateFromUnixTimestamp(filterData.from, true)
        const toDateData = ISODateFromUnixTimestamp(filterData.to, true)

        // Carga fechas en formato string desde el timestamp
        this.selectorModel.sinceDate = sinceDateData.date
        this.selectorModel.toDate = toDateData.date

        if (this.selectorModel.selectedDateAndTimeRangeCustomType === CustomTimeRangeTypes.DATE_AND_TIME) {
          this.selectorModel.sinceTime = sinceDateData.time
          this.selectorModel.toTime = toDateData.time
        }
      }
    },
    /**
     * Inicializador de los datos del selector
     */
    async initializeSelector () {
      const response = await thingApi.getAllTrucks('TRANSPORT')
      this.selectorModel.filters[0].data.cleanAndUpdate(response.data.map(t => ({ id: t._id, name: t.name })))
    },
    loadReport (response, chartRef, refresh = false) {
      const { data, filters } = response.data
      const processedData = truckHistoricalReportService.processData(data)
      if (!refresh) {
        this.summaryTableHeaders.cleanAndUpdate(processedData.headersSummary)
        this.detailTableHeaders.cleanAndUpdate(processedData.headersDetail)
        this.initializeSelectorData(filters)
        this.setTableFilterRules(processedData.headersSummary, processedData.headersDetail)
      }
      this.summaryTableData.cleanAndUpdate(processedData.dataSummary)
      this.detailTableData.cleanAndUpdate(processedData.dataDetail)
      this.updateChart(this.chartConfig, [processedData.chart], chartRef)
      this.settingDisabled = false
      this.updateFilterModelDate(filters)
      this.loadingTable = false
    },
    getData (reportKey) {
      if (reportKey) {
        this.showSelector = false
        this.loadingTable = true
        this.disableRefreshTable = true
        const chartRef = this.$refs['truckHistoricalChart']
        reportStorageApi.getReport(reportKey).then(response => {
          this.loadReport(response, chartRef)
        })
      } else {
        this.showSelector = true
      }
    },
    updateChart (config, newCharts, ref) {
      newCharts.forEach(c => {
        c.options.tooltips = {
          callbacks: {
            label (tooltipItem, data) {
              let index = tooltipItem.index
              let label = ''
              if (data.datasets && data.datasets.length > 0 && (data.datasets[0].data.length >= index) && (data.labels.length >= index)) {
                label = data.labels[index] + ': ' + ReportConversionUtil.secondsToStringFormatted(data.datasets[0].data[index], this.timeFormat)
              }
              return label
            }
          }
        }
      })
      config.cleanAndUpdate(newCharts)
      if (ref) {
        newCharts.forEach(c => {
          ref.reset(c)
        })
      }
    },
    selector () {
      this.showSelector = !this.showSelector
    },
    showSelectorButton () {
      return true
    },
    disableSelectorButton () {
      return this.settingDisabled
    },
    getReport (eventData) {
      const trucksSelected = this.selectorModel.filters[0].selectedData
      this.things = trucksSelected
      this.thingsId = trucksSelected.map(t => t.id)
      const conversion = getDateTimeRange(eventData.dateAndTimeRange, eventData.dateAndTimeRangeCustomType,
        eventData.sinceDate, eventData.sinceTime, eventData.toDate, eventData.toTime)
      this.timeStampFrom = conversion.tFrom
      this.timeStampTo = conversion.tTo
      this.timeFormat = eventData.dateAndTimeFormat
      const that = this
      const chartRef = this.$refs['truckHistoricalChart']
      if (eventData.autoReport) {
        if (that.loadingSpinner) that.setVisible(true)
        that.loadingTable = true
        that.disableRefreshTable = false
        truckApi.getHistoricalReportNotDeferred(that.thingsId, that.timeStampFrom, that.timeStampTo, that.things, that.timeFormat)
          .then(response => {
            that.loadReport(response, chartRef)
            if (that.loadingSpinner) that.setVisible(false)
          })
        clearInterval(this.intervalId)
        this.intervalId = setInterval(
          function () {
            const conversion = getDateTimeRange(eventData.dateAndTimeRange, eventData.dateAndTimeRangeCustomType,
              eventData.sinceDate, eventData.sinceTime, eventData.toDate, eventData.toTime)
            that.timeStampFrom = conversion.tFrom
            that.timeStampTo = conversion.tTo
            that.timeFormat = eventData.dateAndTimeFormat
            if (that.loadingSpinner) that.setVisible(true)
            that.loadingTable = true
            truckApi.getHistoricalReportNotDeferred(that.thingsId, that.timeStampFrom, that.timeStampTo, that.things, that.timeFormat)
              .then(response => {
                that.loadReport(response, chartRef, true)
                if (that.loadingSpinner) that.setVisible(false)
              })
          },
          eventData.autoReportMinutes * 60 * 1000)
      } else {
        truckHistoricalReportService.getHistoricalReportDeferred(this.thingsId, this.timeStampFrom, this.timeStampTo, this.things, this.timeFormat, this.$route.path)
        this.showReportDefer({ updateFunction: this.getData.bind(this) })
      }
      this.showSelector = false
    },
    setReportDetails () {
      this.setDetails({
        detailData: {
          from: this.timeStampFrom,
          to: this.timeStampTo,
          things: this.things,
          reportName: this.reportNameForChart },
        dataStructure: [
          { detailDataProp: 'reportName', propTitle: this.$t('report'), propType: 'LIST', propRefs: { list: this.reportNameForChart, value: 'id', text: 'name' } },
          { detailDataProp: 'from', propTitle: this.$t('since'), propType: 'DATE' },
          { detailDataProp: 'to', propTitle: this.$t('to'), propType: 'DATE' },
          { detailDataProp: 'things', propTitle: this.$t('things'), propType: 'LIST', propRefs: { list: this.things, value: 'id', text: 'name' } }
        ]
      })
    },
    validateSelector () {
      const that = this
      Vue.nextTick(function () {
        const trucksSelected = that.selectorModel.filters[0].selectedData
        const timeFormatSelected = that.selectorModel.selectedTimeFormat
        const customTimeValidation = selectorDateTimeValidation(that.selectorModel.selectedDateAndTimeRange,
          that.selectorModel.selectedDateAndTimeRangeCustomType, that.selectorModel.customDateTimeValidForm,
          that.selectorModel.sinceDate, that.selectorModel.sinceTime, that.selectorModel.toDate, that.selectorModel.toTime)
        that.selectorModel.disableGetReportButton = trucksSelected.length === 0 || customTimeValidation || !timeFormatSelected
      })
    },
    resize () {
      this.$eventBus.$emit('resizeCharts')
    },
    clickRow () {
    },
    refreshTable () {
      const chartRef = this.$refs['truckHistoricalChart']
      const conversion = getDateTimeRange(this.selectorModel.selectedDateAndTimeRange,
        this.selectorModel.selectedDateAndTimeRangeCustomType, this.selectorModel.sinceDate,
        this.selectorModel.sinceTime, this.selectorModel.toDate, this.selectorModel.toTime)
      this.timeStampFrom = conversion.tFrom
      this.timeStampTo = conversion.tTo
      this.timeFormat = this.selectorModel.timeFormatSelected
      if (this.loadingSpinner) this.setVisible(true)
      this.loadingTable = true
      truckApi.getHistoricalReportNotDeferred(this.thingsId, this.timeStampFrom, this.timeStampTo, this.things, this.timeFormat)
        .then(response => {
          this.loadReport(response, chartRef, true)
          if (this.loadingSpinner) this.setVisible(false)
        })
    },
    setTableFilterRules (headersSummary, headersDetail) {
      this.summaryTableFilterRules = {}
      headersSummary.forEach(header => {
        this.summaryTableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })

      this.detailTableFilterRules = {}
      headersDetail.forEach(header => {
        this.detailTableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    /**
     * TO DO: inicializar los datos de fechas y horas en el selector
     * @param {*} filterData
     */
    initializeSelectorData (filterData) {
      this.thingsId = filterData.thingsId
      this.things = filterData.thingsData
      this.selectorModel.filters[0].selectedData.cleanAndUpdate(this.things)
      this.timeStampFrom = filterData.from
      this.timeStampTo = filterData.to
      this.selectorModel.selectedTimeFormat = filterData.timeFormat
      this.setReportDetails()
    },
    tabSelected (id) {
      this.selectedTab = id
    },
    calcsFunction (numbers, id) {
      return statisticFunction(numbers, id)
    },
    calcsFormatter (number) {
      return number
    }
  },
  watch: {
    'selectorModel.filters': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'selectorModel.selects': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'selectorModel.selectedDateAndTimeRange': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'selectorModel.selectedDateAndTimeRangeCustomType': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'selectorModel.sinceDate': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'selectorModel.sinceTime': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'selectorModel.toDate': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'selectorModel.toTime': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'selectorModel.selectedTimeFormat': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    }
  }
}
