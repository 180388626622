import { render, staticRenderFns } from "./HistoricalReport.vue?vue&type=template&id=69dacf98&scoped=true"
import script from "./HistoricalReport.js?vue&type=script&lang=js&external"
export * from "./HistoricalReport.js?vue&type=script&lang=js&external"
import style0 from "./HistoricalReport.vue?vue&type=style&index=0&id=69dacf98&prod&scoped=true&lang=scss"
import style1 from "./HistoricalReport.vue?vue&type=style&index=1&id=69dacf98&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69dacf98",
  null
  
)

export default component.exports