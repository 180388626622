import { FilterType, DataType, Axes, Options, Chart, Dataset, ChartType, Util } from '@colven/common-domain-lib/lib'
import i18n from '@/i18n'

const summaryHeaders = (lang) => {
  return [
    {
      text: i18n.t('truckHistoricalReport.HEADERS.HARVEST_FRONT', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'harvestFrontName'
    },
    {
      text: i18n.t('truckHistoricalReport.HEADERS.TIMES', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'times'
    }
  ]
}

const detailHeaders = (lang) => {
  return [
    {
      text: i18n.t('truckHistoricalReport.HEADERS.TRUCK', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'truck'
    },
    {
      text: i18n.t('truckHistoricalReport.HEADERS.VINCULATION', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'vinculation'
    },
    {
      text: i18n.t('truckHistoricalReport.HEADERS.DISENGAGEMENT', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'disengagement'
    },
    {
      text: i18n.t('truckHistoricalReport.HEADERS.HARVEST_FRONT', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'harvestFront'
    }
  ]
}

const chart = (lang, data) => {
  const yAxes = new Axes(ChartType.PIE)
  const xAxes = new Axes(ChartType.PIE)
  const options = new Options()
  options.tooltips.mode = 'point'
  const chart = new Chart()
  const dataset = new Dataset()
  // id
  chart.id = `chartTotal`
  // nombre
  chart.name = i18n.t('truckHistoricalReport.CHARTS.TITLE', { lang })
  // tipo
  chart.type = ChartType.PIE
  // labels
  let total = 0
  data.forEach(d => { total += d.times })
  chart.data.labels = []
  data.forEach(d => {
    let percentage = 0
    if (total !== 0) {
      percentage = Util.roundDecimals((d.times / total) * 100)
    }
    chart.data.labels.push(d.harvestFrontName + ' (' + percentage + '%)')
  })
  // Dataset
  dataset.data = data.map(d => d.times)
  dataset.label = ''
  yAxes.scaleLabel.labelString = ''
  // Color
  let pieColor

  dataset.backgroundColor = []
  for (let i = 0; i <= dataset.data.length - 1; i++) {
    pieColor = Util.pickColor(dataset.backgroundColor)
    dataset.backgroundColor.push(pieColor)
  }

  dataset.borderWidth = 0
  // Agrego el dataset creado
  chart.data.datasets.push(dataset)
  // Options
  options.title.text = chart.name
  xAxes.scaleLabel.labelString = ''
  options.scales.yAxes.push(yAxes)
  options.scales.xAxes.push(xAxes)

  chart.options = options
  return chart
}

export default {
  summaryHeaders,
  detailHeaders,
  chart
}
